<template>
  <div class="VFX">
      <v-container grid-list-xs>
        <h2 id="VFX">VFX & 3D art</h2>
        <p>I have been using Blender 3D for about 3 years but in the last 2 years, I've been using it pretty frequently. I do both visual effects and 3D art/CG scenes. Most of the time I enter a weekly competition that is held by the pwnisher on youtube. Contestants get a theme and they get a week to render an image or an animation. I also have a youtube channel where I upload tutorials for people that use Blender 3D, which was quite successful. </p>

        <h3>Some of my videos!</h3>
        <v-divider class="mt-5 mb-5"/>
        <v-row class="text-center">
            <v-col>
                <iframe width="320" height="180"
                src="https://www.youtube.com/embed/yJz7LaynDdA">
                </iframe>
            </v-col>
            <v-col>

                <iframe width="320" height="180"
                src="https://www.youtube.com/embed/ma057GWWlbA">
                </iframe>

            </v-col>
            <v-col>
                <iframe width="320" height="180"
                src="https://www.youtube.com/embed/G_JmKxiNljY">
                </iframe>
            </v-col>
        </v-row>
        <v-card width="100" class="mx-auto mt-5" color="transparent" elevation="0">
            <v-btn width="100" height="50"  color="primary" outlined >Portfolio <v-icon>mdi-photo</v-icon></v-btn>
        </v-card>



      </v-container>

  </div>
</template>

<script>
export default {
    name: 'Vfxpart'
}
</script>

<style>
iframe {
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.377) 2px 2px 5px;

}
</style>