<template>
  <div class="Minecraftplugins">
      <v-container class=""  grid-list-xs >
        <h2 id="Minecraftplugins">Minecraft development</h2>
        <p>

I code Minecraft plugins using Java and integrate other tools such as MongoDB with it. I have about 2 years of experience and you can find some open-source projects on my GitHub page. <br>
            (These projects are mostly prototypes because I usually don't finish my projects ¯\_(ツ)_/¯ )

        </p>
        <v-divider class="mb-5"></v-divider>


        <v-layout  row wrap>
        <v-hover v-slot="{ hover }">
          <v-card

    :elevation="hover ? 5 : 0"
    class="mx-auto transition-fast-in-fast-out ma-2"
    max-width="344"
    outlined
    raised
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
            NolzCoding - Github
        </div>
        <v-list-item-title class="text-h5 mb-1">
          StockMarket
        </v-list-item-title>
        <v-list-item-subtitle>A plugin that allows you to buy stocks with any economy plugin that Vault supports. The plugin uses https://finnhub.io/. In order for the plugin to works you need to change the api key in the cofig with the one on your finnhub dashboard. You can create a free account.</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"

      >
      <img src="https://avatars.githubusercontent.com/u/34170158?v=4" alt="">
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        href="https://github.com/NolzCoding/StockMarket"
        text
      >
        See on Github
      </v-btn>
    </v-card-actions>
  </v-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-card

    :elevation="hover ? 5 : 0"
    class="mx-auto transition-fast-in-fast-out ma-2"
    max-width="344"
    outlined
    raised
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
            NolzCoding - Github
        </div>
        <v-list-item-title class="text-h5 mb-1">
          DungeonGenerator
        </v-list-item-title>
        <v-list-item-subtitle>A plugin that generates a random map</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"

      >
      <img src="https://avatars.githubusercontent.com/u/34170158?v=4" alt="">
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        href="https://github.com/NolzCoding/DungeonGenerator"
        text
      >
        See on Github
      </v-btn>
    </v-card-actions>
  </v-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-card

    :elevation="hover ? 5 : 0"
    class="mx-auto transition-fast-in-fast-out ma-2"
    max-width="344"
    outlined
    raised

  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
            NolzCoding - Github
        </div>
        <v-list-item-title class="text-h5 mb-1">
          NServerCore
        </v-list-item-title>
        <v-list-item-subtitle>WaterFall/BungeeCord plugin that allows users to create their own servers </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"

      >
      <img src="https://avatars.githubusercontent.com/u/34170158?v=4" alt="">
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        href="https://github.com/NolzCoding/NServerCore"
        text
      >
        See on Github
      </v-btn>
    </v-card-actions>
  </v-card>
        </v-hover>
        </v-layout>

      </v-container>

  </div>
</template>

<script>
export default {
    name: 'Minecraftplugins'
}

</script>

<style>
iframe {
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.377) 2px 2px 5px;
}

</style>