<template>
    <v-layout row wrap>
    <v-hover v-slot="{ hover }">
  <v-card

    :elevation="hover ? 5 : 0"
    class="mx-auto transition-fast-in-fast-out ma-2"
    max-width="344"
    outlined
    raised
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
            Art
        </div>
        <v-list-item-title class="text-h5 mb-1">
          VFX & 3D Art
        </v-list-item-title>
        <v-list-item-subtitle>I do Visual effects aswell as 3D Art in Blender 3D</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"

      >
      <img src="https://icon-library.com/images/blender-3d-icon/blender-3d-icon-5.jpg" alt="">
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        href="#VFX"
        text
      >
        Read more
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-hover>
    <v-hover v-slot="{ hover }">
    <v-card
    :elevation="hover ? 5 : 0"
    class="mx-auto transition-fast-in-fast-out ma-2"
    max-width="344"
    outlined
    raised

  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
            Web
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Full stack
        </v-list-item-title>
        <v-list-item-subtitle>I do full stack web development, using Vuejs and Nodejs.</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"

      >
      <img src="https://vuejs.org/images/logo.png" alt="">
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        text

      >
        Read more
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-hover>

    <v-hover v-slot="{ hover }">
    <v-card
    :elevation="hover ? 5 : 0"
    class="mx-auto transition-fast-in-fast-out ma-2"
    max-width="344"
    outlined
    raised
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
            Game dev
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Minecraft plugins
        </v-list-item-title>
        <v-list-item-subtitle>I code Minecraft plugins using Java and integrate other tools such as MongoDB with it</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"

      >
      <img src="https://cdn.icon-icons.com/icons2/2699/PNG/512/minecraft_logo_icon_168974.png" alt="">
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined

        text
      >
        Read more
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-hover>

    <v-hover v-slot="{ hover }">
    <v-card
    :elevation="hover ? 5 : 0"
    class="mx-auto transition-fast-in-fast-out ma-2"
    max-width="344"
    outlined
    raised
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
            Music
        </div>
        <v-list-item-title class="text-h5 mb-1">
          Music producer
        </v-list-item-title>
        <v-list-item-subtitle>I produce music mainly Electronic music but i also make beats, I recently made a spotify</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"

      >
      <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fclipartart.com%2Fimages%2Fspotify-logo-clipart-2018-1.png&f=1&nofb=1" alt="">
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined

        text
      >
        Read more
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-hover>

</v-layout>
</template>

<script>
export default {
    name: 'Hobbycards'
}
</script>

<style>


</style>