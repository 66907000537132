<template>
  <div class="Fullstack">
      <v-container class=""  grid-list-xs >
        <h2 id="Fullstack">Full stack web development</h2>
        <p>
            I do full stack web development, using Vuejs and Nodejs(TS). One example of this is this website which is built using VueJS.
        </p>



      </v-container>

  </div>
</template>

<script>
export default {
    name: 'Fullstack'
}
</script>

<style>
iframe {
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.377) 2px 2px 5px;
}
</style>