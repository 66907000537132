var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto transition-fast-in-fast-out ma-2",attrs:{"elevation":hover ? 5 : 0,"max-width":"344","outlined":"","raised":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" Art ")]),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" VFX & 3D Art ")]),_c('v-list-item-subtitle',[_vm._v("I do Visual effects aswell as 3D Art in Blender 3D")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"80"}},[_c('img',{attrs:{"src":"https://icon-library.com/images/blender-3d-icon/blender-3d-icon-5.jpg","alt":""}})])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","href":"#VFX","text":""}},[_vm._v(" Read more ")])],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto transition-fast-in-fast-out ma-2",attrs:{"elevation":hover ? 5 : 0,"max-width":"344","outlined":"","raised":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" Web ")]),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Full stack ")]),_c('v-list-item-subtitle',[_vm._v("I do full stack web development, using Vuejs and Nodejs.")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"80"}},[_c('img',{attrs:{"src":"https://vuejs.org/images/logo.png","alt":""}})])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","text":""}},[_vm._v(" Read more ")])],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto transition-fast-in-fast-out ma-2",attrs:{"elevation":hover ? 5 : 0,"max-width":"344","outlined":"","raised":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" Game dev ")]),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Minecraft plugins ")]),_c('v-list-item-subtitle',[_vm._v("I code Minecraft plugins using Java and integrate other tools such as MongoDB with it")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"80"}},[_c('img',{attrs:{"src":"https://cdn.icon-icons.com/icons2/2699/PNG/512/minecraft_logo_icon_168974.png","alt":""}})])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","text":""}},[_vm._v(" Read more ")])],1)],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto transition-fast-in-fast-out ma-2",attrs:{"elevation":hover ? 5 : 0,"max-width":"344","outlined":"","raised":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" Music ")]),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Music producer ")]),_c('v-list-item-subtitle',[_vm._v("I produce music mainly Electronic music but i also make beats, I recently made a spotify")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"80"}},[_c('img',{attrs:{"src":"https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fclipartart.com%2Fimages%2Fspotify-logo-clipart-2018-1.png&f=1&nofb=1","alt":""}})])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","text":""}},[_vm._v(" Read more ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }