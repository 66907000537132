<template>
  <div>
    <v-parallax ma-0 height="450" src="@/assets/Nolz.jpg">
      <h1  class="text-center">Nolz</h1>
      <v-card class="mx-auto" elevation="0" color="transparent">
        <vue-typed-js

        :loop="true"
        :strings="['VFX', 'CGI', 'GAME DEV', 'PHOTOGRAPHY', 'FILM MAKING']"
      >
        <p class="text-center"> <span class="typing"></span></p>
      </vue-typed-js>
      </v-card>


      <v-btn
        target="_blank"
        href="https://twitter.com/nolzvfx"
        class="text-center"
        outline
        color="white"
        icon
        dark
        ><v-icon class="text-center">mdi-twitter</v-icon></v-btn
      >
      <v-btn
        target="_blank"
        href="https://github.com/NolzCoding"
        class="text-center"
        outline
        color="white"
        icon
        dark
        ><v-icon class="text-center">mdi-instagram</v-icon></v-btn
      >
      <v-btn
        target="_blank"
        href="https://www.instagram.com/nolzzimusic/"
        class="text-center"
        outline
        color="white"
        icon
        dark
        ><v-icon class="text-center">mdi-github</v-icon></v-btn
      >
    </v-parallax>
    <v-container fluid class="elevation-3" grid-list-xs>
      <h1>What is this?</h1>
      <p>
        A Website about my personal projects in multiple areas like coding and
        CGI
      </p>
    </v-container>

    <v-spacer ma-5></v-spacer>

    <v-divider ma-5></v-divider>
    <v-container grid-list-xs>
      <hobbycards />
    </v-container>
    <v-container class="text-center">
      <v-btn
        icon
        outlined
        color="primary"
        x-large
        @click="$vuetify.goTo('#VFX')"
      >
        <v-icon>mdi-arrow-down</v-icon>
      </v-btn>
    </v-container>
    <v-container
      class="orange"
      style="border-top-right-radius: 5px; border-top-left-radius: 5px"
      grid-list-xs
    >
      <v-spacer></v-spacer>
    </v-container>
    <vfxpart></vfxpart>
    <v-spacer class="mt-15"></v-spacer>
    <v-container
      class="orange"
      style="border-top-right-radius: 5px; border-top-left-radius: 5px"
      grid-list-xs
    >
      <v-spacer></v-spacer>
    </v-container>

    <fullstack></fullstack>
    <v-spacer class="mt-15"> </v-spacer>

    <v-container
      class="orange"
      style="border-top-right-radius: 5px; border-top-left-radius: 5px"
      grid-list-xs
    >
      <v-spacer></v-spacer>
    </v-container>
    <minecraftplugins></minecraftplugins>
  </div>
</template>

<script>
import Fullstack from "../components/Fullstack.vue";
import Hobbycards from "../components/Hobbycards.vue";
import Minecraftplugins from "../components/Minecraftplugins.vue";
import Vfxpart from "../components/Vfxpart.vue";

export default {
  name: "Home",

  data() {
    return {
      options: {
        easing: "easeInOutCubic",
        duration: 1000,
      },
    };
  },

  components: {
    Hobbycards,
    Vfxpart,
    Fullstack,
    Minecraftplugins,
  },
};
</script>
<style scoped>
</style>