<template>
  <v-app class="ma-0">
    <v-app-bar app :color="supportsBlur()" class="blur-content ma-0">
      <div class="d-flex align-center">
        <h1>Nolz</h1>
      </div>
      <v-btn class="ml-5" :ripple="false" to="/" tile outlined color="orange "
        >Home</v-btn
      >

      <v-btn to="/portfolio" class="ml-5" :ripple="false" tile outlined color="orange "
        >Portfolio <v-icon class="ml-2">mdi-camera</v-icon></v-btn
      >

      <v-spacer></v-spacer>

      <v-btn target="_blank" icon class="text-center" @click="changeTheme()">
        <v-icon>mdi-brightness-2</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    supportsBlur() {
      if (CSS.supports("backdrop-filter", "blur(10px)")) {
        return "rgba(255,255,255,0.1)";
      } else {
        return "secondary";
      }
    },
  },
};
</script>

<style scoped>
.blur-content {
  backdrop-filter: blur(10px);
}
</style>
