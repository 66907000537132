import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
              primary: colors.deepOrange,
              accent: colors.deepOrange.lighten1,
              secondary: colors.grey.lighten4
            },
            dark: {
                primary: colors.deepOrange,
                accent: colors.deepOrange.lighten1,
                secondary: colors.grey.darken5

              },
          },

    },

});
